import React, { Component } from "react"
import Markdown from "../components/Markdown"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
var classNames = require("classnames")

class Content extends Component {
  render() {
    const content = this.props.content

		const rowClass = element => {
      const extraClasses = {}
      if (element.extraClasses && element.extraClasses.length) {
        const classArray = element.extraClasses.split(' ')
        classArray.forEach(className => {
          extraClasses[className] = true
        });
      }
      const classes = {
        "justify-content-md-center": true,
				"flex-row-reverse-sm": element.flipColumnsOnMobile === "Yes",
        "text-center": element.centerAlignText.toString() === "true",
        "align-items-md-start": element.verticalAlignment === "Top",
        "align-items-md-center": element.verticalAlignment === "Middle",
        "align-items-md-end": element.verticalAlignment === "Bottom",
      }
      return Object.assign(classes, extraClasses)
    }

    const colClass = element => {
      return {
        "col-lg-8": element.narrowColumn.toString() === "true",
      }
    }

    const contentClass = element => {
      return {
        "content-section": true,
        "bg-light": element.color.toString() === "true",
        "bg-dark": element.color.toString() === "false",
        "pt-lg": element.topPadding === "Large",
        "font-size-small": element.fontSize === "Small",
        "pb-lg": element.bottomPadding === "Large",
        "pt-md": element.topPadding === "Medium",
        "pb-md": element.bottomPadding === "Medium",
        "pt-sm": element.topPadding === "Small",
        "pb-sm": element.bottomPadding === "Small",
        "pt-0": element.topPadding === "None",
        "pb-0": element.bottomPadding === "None",
      }
    }

    

    return (
      <div id="content" className="content">
        {content.map((element, index) => {
          return (
            <div key={index} className={classNames(contentClass(element))}>
              <Container>
                <Row className={classNames(rowClass(element))}>
                  {element.column1Content && (
                    <Col className={classNames(colClass(element))}>
                      <Markdown content={element.column1Content} />
                    </Col>
                  )}
                  {element.column2Content && (
                    <Col>
                      <Markdown content={element.column2Content} />
                    </Col>
                  )}
									{element.column3Content && (
                    <Col>
                      <Markdown content={element.column3Content} />
                    </Col>
                  )}
									{element.column4Content && (
                    <Col>
                      <Markdown content={element.column4Content} />
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Content
