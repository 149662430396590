import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import App from "../components/App";
import Hero from "../components/Hero";
import Content from "../components/Content";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import SEO from "../components/seo";

import persosaImage from "../assets/images/persosa-og.jpg";

class Page extends Component {
  render() {
    const { cta, hero, content, title, description } = this.props.data.allContentfulPage.edges[0].node;
    const navbar = this.props.data.allContentfulNavbar.edges[0].node;
    const footerData = this.props.data.allContentfulFooter.edges[0].node;

		const returnImage = () => {
      if (
        hero.backgroundImage &&
        hero.backgroundImage.file &&
        hero.backgroundImage.file.url
      ) {
        return hero.backgroundImage.file.url
      } else {
        return persosaImage
      }
		}

    return (
      <App navbar={navbar} >
        <SEO title={title} description={description.internal.content} image={returnImage()}/>
				{hero &&
        	<Hero navbar={navbar} hero={hero} />
				}

				<Content content={content} />

				{cta &&
        	<CTA cta={cta} />
				}

				<Footer footer={footerData} />
      </App>
    )
  }
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    allContentfulPage(filter: {slug: {eq: $slug }}) {
      edges {
        node {
          id
          title
          description {
            internal {
              content
            }
          }
          cta {
            title
            buttonCTA {
              link
              label
            }
          }
          hero {
            title
            label
            backgroundImage {
              file {
                url
              }
            }
            content {
              json
            }
          }
          content {
            title
            centerAlignText
            topPadding
            fontSize
            bottomPadding
            narrowColumn
            color
						verticalAlignment
						flipColumnsOnMobile
						extraClasses
            column1Content {
              json
            }
            column2Content {
              json
            }
            column3Content {
              json
            }
            column4Content {
              json
            }
          }
        }
      }
    },
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`

